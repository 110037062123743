// extracted by mini-css-extract-plugin
export var desktopTitle = "Project-module--desktopTitle--FgAuz";
export var fadeInDown = "Project-module--fadeInDown--32DPi";
export var first = "Project-module--first---XOxA";
export var image = "Project-module--image--T-By-";
export var lightboxContainer = "Project-module--lightboxContainer--N30S2";
export var mobileTitle = "Project-module--mobileTitle--lw6fw";
export var navIcon = "Project-module--navIcon--JgySM";
export var project = "Project-module--project--mFCyZ";
export var reading = "Project-module--reading--0wXzV";
export var thumb = "Project-module--thumb--LELb4";
export var thumbWrap = "Project-module--thumbWrap--VXTCZ";
export var videoWrap = "Project-module--videoWrap--LiYVE";
export var visual = "Project-module--visual--9yJYt";